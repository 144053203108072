<template>
  <div class="navbar-container d-flex content align-items-center">
    <slot
      name="header"
      :toggleVerticalMenuActive="toggleVerticalMenuActive"
      :toggleCollapsed="toggleCollapsed"
      :collapseTogglerIcon="collapseTogglerIcon"
    >
      <b-link class="nav-link modern-nav-toggle">
        <feather-icon
          :icon="collapseTogglerIconFeather"
          size="20"
          class="d-none d-xl-block collapse-toggle-icon"
          @click="toggleCollapsed"
        />
      </b-link>
    </slot>
    <div class="icons-top align-items-center">
      <h4 class="content-header-title float-left pr-1 mb-0">
        {{ $t($route.meta.pageTitle) }}
      </h4>
      <div class="breadcrumb-wrapper">
        <b-breadcrumb>
          <b-breadcrumb-item to="/">
            <feather-icon
              icon="HomeIcon"
              size="16"
              class="align-text-top"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item
            v-for="item in $route.meta.breadcrumb"
            :key="item.text"
            :active="item.active"
            :to="item.to"
          >
            {{ $t(item.text) }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-link
        class="nav-link"
        @click="linkWebmail"
      >
        <feather-icon
          icon="MailIcon"
          size="21"
      /></b-link>
      <no-responsive />
      <dark-Toggler class="d-none d-lg-block" />
      <locale />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  VBTooltip,
  BBreadcrumb,
  BBreadcrumbItem,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import useVerticalNavMenu from '../../layout-vertical/components/vertical-nav-menu/useVerticalNavMenu'
import Locale from './components/Locale.vue'
import NoResponsive from './components/NoResponsive.vue'
import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    // Navbar Components
    BNavbarNav,
    BBreadcrumbItem,
    BBreadcrumb,
    Locale,
    DarkToggler,
    UserDropdown,
    NoResponsive,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    linkWebmail() {
      window.open('https://webmail.vevida.com/SOGo')
    },
  },
  setup(props) {
    const {
      collapseTogglerIcon,
      toggleCollapsed,
    } = useVerticalNavMenu(props)

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    return {
      collapseTogglerIconFeather,
      collapseTogglerIcon,
      toggleCollapsed,
    }
  },
}
</script>
<style>
  .icons-top {
    display: flex;
  }
  @media screen and (max-width: 1199px) {
    .icons-top {
      display: none !important;
    }
  }
</style>
